<template>
  <div class="w-screen h-screen">
    <!-- logo -->
    <div class="w-full">
      <img class="p-4 w-1/3 w-768" src="../../src/assets/autralis-digital-experience-logo-transparent-bg.png"
           alt="digital-experience">
    </div>
    <!-- passcode -->
    <div class="w-full h-calc-sm h-calc-biggest flex justify-center items-center">
      <div class="flex flex-col items-center">
        <h1 class="text-3xl lg:text-4xl xl:text-5xl 2xl:text-7xl text-white font-bold">
          Passcode
        </h1>
        <div class="flex justify-center items-center text-4xl lg:text-5xl xl:text-6xl 2xl:text-8xl font-bold bg-autralis-purple
             w-auto h-auto pl-6 lg:pl-8 2xl:pl-12 py-2 lg:py-4 xl:py-6 mt-4 xl:mt-6 2xl:mt-8 rounded-xl">
          <div v-model="confKey" class="text-white text-center tracking-very-wide">
            {{ confKey }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Confirmation",
  data() {
    return {
      confKey: localStorage.getItem('randomNumber'),
    }
  },

  mounted() {
    const STORAGEKEY = 'randomNumber'
    const randomNumberExists = localStorage.getItem(STORAGEKEY) !== null
    if (!randomNumberExists) {
      const randomNumber = Math.random().toString(36).substring(2, 8).toUpperCase();
      localStorage.setItem('randomNumber', randomNumber);
      this.confKey = randomNumber;
    }
  }

}
</script>

<style scoped>

</style>
