import Vue from 'vue'
import App from './App.vue'
import './assets/styles/index.css'
import axios from 'axios'
import VueAxios from 'vue-axios'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
Vue.component('font-awesome-icon', FontAwesomeIcon)

import { library } from '@fortawesome/fontawesome-svg-core'
import { faSpinner } from '@fortawesome/pro-solid-svg-icons'
import { faRoad } from '@fortawesome/pro-light-svg-icons'
import { faChargingStation, faTachometerAltFast, faLeaf, faCalendarWeek } from '@fortawesome/pro-regular-svg-icons'

library.add(faSpinner, faRoad, faChargingStation, faTachometerAltFast, faLeaf, faCalendarWeek)



Vue.use(VueAxios, axios)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
